<template>
  <div>
    <b-row align-v="stretch" class="mb-2">
      <b-col cols="6" class="pr-1">
        <b-card class="h-100">
          <div slot="header"><strong>고객 보상 제외 조건</strong></div>
          <b-alert show variant="danger">
            <ul class="mb-0 pl-3">
              <li>상품의 고객 보상 제외 조건을 HUB에 등록된 전체 상품 기준으로 설정합니다.</li>
              <li>
                '배송, 상품 판매 유형, 카테고리'를 복수의 조건으로 설정한 경우 OR 적용됩니다.
                <ul>
                  <li>
                    예) '상품 판매유형: 빈티지만 + 카테고리: 홈/리빙'을 설정한 경우<br>
                    빈티지 상품과 홈/리빙 카테고리 상품 모두 고객 보상 제외 상품으로 적용됩니다.
                  </li>
                </ul>
              </li>
              <li>고객 보상 제외 조건을 설정하고 저장하는 즉시 고객 보상 제외 또는 대상 상품으로 분류됩니다.</li>
              <li>고객 보상 제외 조건을 수정한 이후 생성된 주문완료(결제여부 무관) 건부터 고객 보상에서 제외됩니다.</li>
            </ul>
          </b-alert>

          <b-form v-if="settings">
            <b-form-checkbox v-model="settings.enableDeliveryExclusion">배송</b-form-checkbox>
            <b-form-checkbox-group
              class="ml-4"
              v-model="settings.excludedDeliveryTypes"
              :disabled="!settings.enableDeliveryExclusion"
              :options="[
                { text: '해외 + 일반 택배배송', value: { delivery_type: '해외', orderMade: false, deliveryExtra: [] } },
                { text: '해외 + 가구배송', value: { delivery_type: '해외', orderMade: false, deliveryExtra: ['long'] } },
                { text: '해외 + 일반 택배배송 + 주문제작 상품', value: { delivery_type: '해외', orderMade: true, deliveryExtra: [] } },
                { text: '해외 + 가구배송 + 주문제작 상품', value: { delivery_type: '해외', orderMade: true, deliveryExtra: ['long'] } },

                { text: '국내 + 일반 택배배송', value: { delivery_type: '국내', orderMade: false, deliveryExtra: [] } },
                { text: '국내 + 가구배송', value: { delivery_type: '국내', orderMade: false, deliveryExtra: ['long'] } },
                { text: '국내 + 일반 택배배송 + 주문제작 상품', value: { delivery_type: '국내', orderMade: true, deliveryExtra: [] } },
                { text: '국내 + 가구배송 + 주문제작 상품', value: { delivery_type: '국내', orderMade: true, deliveryExtra: ['long'] } },
              ]"
              stacked>
            </b-form-checkbox-group>

            <b-form-checkbox class="mt-4" v-model="settings.enableGoodsTypeExclusion">상품 판매 유형</b-form-checkbox>
            <b-form-radio-group
              class="ml-4"
              v-model="settings.excludedGoodsTypes"
              v-if="settings.enableGoodsTypeExclusion"
              :options="[
                { text: '새상품 + 빈티지', value: ['new', 'used'] },
                { text: '새상품만', value: ['new'] },
                { text: '빈티지만', value: ['used'] },
                ]"
              stacked>
            </b-form-radio-group>
            <!-- dummy radio group -->
            <b-form-radio-group
              class="ml-4"
              v-if="!settings.enableGoodsTypeExclusion"
              :disabled="true"
              :options="[
                { text: '새상품 + 빈티지', value: ['new', 'used'] },
                { text: '새상품만', value: ['new'] },
                { text: '빈티지만', value: ['used'] },
                ]"
              :checked="null"
              stacked>
            </b-form-radio-group>

            <b-form-checkbox class="mt-4" v-model="settings.enableCategoryExclusion">카테고리</b-form-checkbox>

            <category-depth-preset class="mt-2" v-model="selectedCategory" :disabled="!settings.enableCategoryExclusion"></category-depth-preset>

            <b-row align-h="start" class="my-2">
              <b-col cols="auto">
                <b-button variant="success" @click="addExcludedCategories([selectedCategory])">추가</b-button>
              </b-col>
              <b-col cols="auto" class="pl-0">
                <b-button variant="danger" @click="removeExcludedCategories(excludedCategoriesData.filter(i => i.checked).map(i => i.category))">삭제</b-button>
              </b-col>
            </b-row>

            <div class="my-3">
              {{ excludedCategoriesData.length }}개 카테고리
            </div>

            <b-row>
              <b-col>
                <hot-table
                  ref="excludedCategoriesTable"
                  :settings="{
                    width: '100%',
                    height: 380,
                    stretchH: 'last', 
                    colHeaders: ['', '카테고리'],
                    columns: [
                      { data: 'checked', type: 'checkbox' },
                      {
                        data: 'category',
                        renderer(instance, td, row, col, prop, value, cellProperties) {
                          td.innerText = `${getCategoryPath(value)} (${value})`;
                          return td;
                        },
                      },
                    ],
                  }"
                  :data="excludedCategoriesData"
                  licenseKey="non-commercial-and-evaluation">
                </hot-table>
                <div v-if="!excludedCategoriesData.length">
                  추가된 고객 보상 제외 카테고리가 없습니다.
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
      <b-col cols="6" class="pl-1">
        <b-card class="h-100">
          <div slot="header"><strong>고객 보상 제외 SHOP 설정</strong></div>
          <b-alert show variant="danger">
            <ul class="mb-0 pl-3">
              <li>'고객 보상 제외 SHOP 설정'을 통해 SHOP에 등록된 전체 상품을 고객 보상 제외로 설정할 수 있습니다.</li>
              <li>고객 보상 제외 SHOP 설정이 다른 설정들 보다 우선됩니다.</li>
              <li>고객 보상 제외 조건을 설정하고 저장하는 즉시 고객 보상 제외 또는 대상 상품으로 분류됩니다.</li>
              <li>고객 보상 제외 조건을 수정한 이후 생성된 주문완료(결제여부 무관) 건부터 고객 보상에서 제외됩니다.</li>
            </ul>
          </b-alert>

          <shop-preset v-model="selectedShops"></shop-preset>

          <b-form v-if="settings">
            <b-row class="my-3">
              <b-col cols="auto">
                <b-button variant="success" @click="addExcludedShop(selectedShops.map(i => i.shop_id))">추가</b-button>
              </b-col>
              <b-col cols="auto p-0">
                <b-button variant="danger" @click="removeExcludedShop(excludedShopsData.filter(i => i.checked).map(i => i.shop_id))">삭제</b-button>
              </b-col>
            </b-row>

            <div class="my-3">
              {{ excludedShopsData.length }}개 SHOP
            </div>

            <b-row>
              <b-col>
                <hot-table
                  ref="excludedShopsTable"
                  :settings="{
                    width: '100%',
                    height: 'auto',
                    stretchH: 'last', 
                    colHeaders: ['', 'SHOP'],
                    columns: [
                      { data: 'checked', type: 'checkbox' },
                      {
                        renderer(instance, td, row, col, prop, value, cellProperties) {
                          const shop_id = instance.getDataAtRowProp(row, 'shop_id');
                          const boutique = shopMap[shop_id].boutique;
                          td.innerText = `${shop_id}. ${boutique}`;
                          return td;
                        },
                      },
                    ],
                  }"
                  :data="excludedShopsData"
                  licenseKey="non-commercial-and-evaluation">
                </hot-table>
                <div v-if="!excludedShopsData.length">
                  추가된 SHOP이 없습니다.
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-row align-h="end">
            <b-col cols="auto">
              <b-button variant="primary" @click="save()">저장</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { HotTable } from '@handsontable/vue';

export default {
  name: 'RewardSetting',
  title: '고객 보상 제외 설정',
  components: { HotTable },
  data() {
    return {
      orgSettings: null,
      settings: null,

      excludedCategoriesData: [],
      excludedShopsData: [],

      selectedShops: [],
      selectedCategory: null,

      categoryMap: {},
      shopMap: {},
    };
  },
  async created() {
    await this.$api.getMeta('shop,category').then(resp => {
      const { shop, category } = resp;

      this.categoryMap = category.reduce((prev, cur) => {
        prev[cur.category] = cur;
        return prev;
      }, {});
      this.shopMap = shop.reduce((prev, cur) => {
        prev[cur.shop_id] = cur;
        return prev;
      }, {});
    });

    const resp = await this.$api.getJson('/order/reward/settings');

    const { ok, settings, msg } = resp;

    if (!ok) {
      console.error(msg);
      return;
    }

    settings.excludedShopIds.sort((a, b) => a - b);
    this.orgSettings = this.$utils.clone(settings);
    this.settings = settings;

    this.$watch('settings.excludedCategories', (newValue) => {
      if (!newValue) return;

      this.excludedCategoriesData.splice(0, this.excludedCategoriesData.length);
      this.excludedCategoriesData.push(...(newValue || []).map(i => ({ checked: false, category: i })));
    }, { immediate: true });

    this.$watch('settings.excludedShopIds', (newValue) => {
      if (!newValue) return;

      this.excludedShopsData.splice(0, this.excludedShopsData.length);
      this.excludedShopsData.push(...(newValue || []).map(i => ({ checked: false, shop_id: i })));
    }, { immediate: true });
  },
  beforeRouteLeave(to, from, next) {
    const isModified = JSON.stringify(this.settings) !== JSON.stringify(this.orgSettings);

    if (!isModified) {
      next();
    } else if (confirm(['현재 화면에서 나가시겠습니까?', '변경사항이 저장되지 않을 수 있습니다.'].join('\n'))) {
      next();
    }
  },
  methods: {
    getCategoryPath(categoryCode) {
      const paths = [];

      for (let i = 0; i < categoryCode.length; i += 3) {
        const segment = categoryCode.substring(0, i + 3);

        paths.push(this.categoryMap[segment].category_nm);
      }

      return paths.join(' > ');
    },
    addExcludedCategories(categoryCodes) {
      categoryCodes = categoryCodes.filter(i => i);

      if (!categoryCodes.length) {
        alert('카테고리를 선택해주세요');
        return;
      }

      this.settings.excludedCategories = Array.from(new Set([
        ...this.settings.excludedCategories, ...categoryCodes,
      ]));
    },
    removeExcludedCategories(categoryCodes) {
      categoryCodes = categoryCodes.filter(i => i);

      if (!categoryCodes.length) {
        alert('카테고리를 선택해주세요');
        return;
      }

      for (const categoryCode of categoryCodes) {
        const idx = this.settings.excludedCategories.findIndex(i => i === categoryCode);
        if (idx < 0) continue;
        this.settings.excludedCategories.splice(idx, 1);
      }
    },
    addExcludedShop(shop_ids) {
      shop_ids = shop_ids.filter(i => i);

      if (!shop_ids.length) {
        alert('SHOP을 선택해주세요');
        return;
      }

      this.settings.excludedShopIds = Array.from(new Set([
        ...shop_ids,
        ...(this.settings.excludedShopIds || []),
      ]));
    },
    removeExcludedShop(shop_ids) {
      shop_ids = shop_ids.filter(i => i);

      if (!shop_ids.length) {
        alert('SHOP을 선택해주세요');
        return;
      }

      for (const shop_id of shop_ids) {
        const idx = this.settings.excludedShopIds.findIndex(i => i === shop_id);
        if (idx < 0) continue;
        this.settings.excludedShopIds.splice(idx, 1);
      }
    },
    async save() {
      if (!confirm('저장하시겠습니까?')) return;

      const resp = await this.$api.postJson('/order/reward/settings', { settings: this.settings });

      if (resp.ok) {
        alert('저장되었습니다.');
        this.settings.excludedShopIds.sort((a, b) => a - b);
        this.orgSettings = this.$utils.clone(this.settings);
      } else {
        alert('저장 중 오류가 발생했습니다.');
      }
    },
  },
};
</script>
