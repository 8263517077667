var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mb-2",attrs:{"align-v":"stretch"}},[_c('b-col',{staticClass:"pr-1",attrs:{"cols":"6"}},[_c('b-card',{staticClass:"h-100"},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('strong',[_vm._v("고객 보상 제외 조건")])]),_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('ul',{staticClass:"mb-0 pl-3"},[_c('li',[_vm._v("상품의 고객 보상 제외 조건을 HUB에 등록된 전체 상품 기준으로 설정합니다.")]),_c('li',[_vm._v(" '배송, 상품 판매 유형, 카테고리'를 복수의 조건으로 설정한 경우 OR 적용됩니다. "),_c('ul',[_c('li',[_vm._v(" 예) '상품 판매유형: 빈티지만 + 카테고리: 홈/리빙'을 설정한 경우"),_c('br'),_vm._v(" 빈티지 상품과 홈/리빙 카테고리 상품 모두 고객 보상 제외 상품으로 적용됩니다. ")])])]),_c('li',[_vm._v("고객 보상 제외 조건을 설정하고 저장하는 즉시 고객 보상 제외 또는 대상 상품으로 분류됩니다.")]),_c('li',[_vm._v("고객 보상 제외 조건을 수정한 이후 생성된 주문완료(결제여부 무관) 건부터 고객 보상에서 제외됩니다.")])])]),(_vm.settings)?_c('b-form',[_c('b-form-checkbox',{model:{value:(_vm.settings.enableDeliveryExclusion),callback:function ($$v) {_vm.$set(_vm.settings, "enableDeliveryExclusion", $$v)},expression:"settings.enableDeliveryExclusion"}},[_vm._v("배송")]),_c('b-form-checkbox-group',{staticClass:"ml-4",attrs:{"disabled":!_vm.settings.enableDeliveryExclusion,"options":[
              { text: '해외 + 일반 택배배송', value: { delivery_type: '해외', orderMade: false, deliveryExtra: [] } },
              { text: '해외 + 가구배송', value: { delivery_type: '해외', orderMade: false, deliveryExtra: ['long'] } },
              { text: '해외 + 일반 택배배송 + 주문제작 상품', value: { delivery_type: '해외', orderMade: true, deliveryExtra: [] } },
              { text: '해외 + 가구배송 + 주문제작 상품', value: { delivery_type: '해외', orderMade: true, deliveryExtra: ['long'] } },

              { text: '국내 + 일반 택배배송', value: { delivery_type: '국내', orderMade: false, deliveryExtra: [] } },
              { text: '국내 + 가구배송', value: { delivery_type: '국내', orderMade: false, deliveryExtra: ['long'] } },
              { text: '국내 + 일반 택배배송 + 주문제작 상품', value: { delivery_type: '국내', orderMade: true, deliveryExtra: [] } },
              { text: '국내 + 가구배송 + 주문제작 상품', value: { delivery_type: '국내', orderMade: true, deliveryExtra: ['long'] } } ],"stacked":""},model:{value:(_vm.settings.excludedDeliveryTypes),callback:function ($$v) {_vm.$set(_vm.settings, "excludedDeliveryTypes", $$v)},expression:"settings.excludedDeliveryTypes"}}),_c('b-form-checkbox',{staticClass:"mt-4",model:{value:(_vm.settings.enableGoodsTypeExclusion),callback:function ($$v) {_vm.$set(_vm.settings, "enableGoodsTypeExclusion", $$v)},expression:"settings.enableGoodsTypeExclusion"}},[_vm._v("상품 판매 유형")]),(_vm.settings.enableGoodsTypeExclusion)?_c('b-form-radio-group',{staticClass:"ml-4",attrs:{"options":[
              { text: '새상품 + 빈티지', value: ['new', 'used'] },
              { text: '새상품만', value: ['new'] },
              { text: '빈티지만', value: ['used'] } ],"stacked":""},model:{value:(_vm.settings.excludedGoodsTypes),callback:function ($$v) {_vm.$set(_vm.settings, "excludedGoodsTypes", $$v)},expression:"settings.excludedGoodsTypes"}}):_vm._e(),(!_vm.settings.enableGoodsTypeExclusion)?_c('b-form-radio-group',{staticClass:"ml-4",attrs:{"disabled":true,"options":[
              { text: '새상품 + 빈티지', value: ['new', 'used'] },
              { text: '새상품만', value: ['new'] },
              { text: '빈티지만', value: ['used'] } ],"checked":null,"stacked":""}}):_vm._e(),_c('b-form-checkbox',{staticClass:"mt-4",model:{value:(_vm.settings.enableCategoryExclusion),callback:function ($$v) {_vm.$set(_vm.settings, "enableCategoryExclusion", $$v)},expression:"settings.enableCategoryExclusion"}},[_vm._v("카테고리")]),_c('category-depth-preset',{staticClass:"mt-2",attrs:{"disabled":!_vm.settings.enableCategoryExclusion},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}}),_c('b-row',{staticClass:"my-2",attrs:{"align-h":"start"}},[_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.addExcludedCategories([_vm.selectedCategory])}}},[_vm._v("추가")])],1),_c('b-col',{staticClass:"pl-0",attrs:{"cols":"auto"}},[_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){_vm.removeExcludedCategories(_vm.excludedCategoriesData.filter(function (i) { return i.checked; }).map(function (i) { return i.category; }))}}},[_vm._v("삭제")])],1)],1),_c('div',{staticClass:"my-3"},[_vm._v(" "+_vm._s(_vm.excludedCategoriesData.length)+"개 카테고리 ")]),_c('b-row',[_c('b-col',[_c('hot-table',{ref:"excludedCategoriesTable",attrs:{"settings":{
                  width: '100%',
                  height: 380,
                  stretchH: 'last', 
                  colHeaders: ['', '카테고리'],
                  columns: [
                    { data: 'checked', type: 'checkbox' },
                    {
                      data: 'category',
                      renderer: function renderer(instance, td, row, col, prop, value, cellProperties) {
                        td.innerText = (_vm.getCategoryPath(value)) + " (" + value + ")";
                        return td;
                      },
                    } ],
                },"data":_vm.excludedCategoriesData,"licenseKey":"non-commercial-and-evaluation"}}),(!_vm.excludedCategoriesData.length)?_c('div',[_vm._v(" 추가된 고객 보상 제외 카테고리가 없습니다. ")]):_vm._e()],1)],1)],1):_vm._e()],1)],1),_c('b-col',{staticClass:"pl-1",attrs:{"cols":"6"}},[_c('b-card',{staticClass:"h-100"},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('strong',[_vm._v("고객 보상 제외 SHOP 설정")])]),_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('ul',{staticClass:"mb-0 pl-3"},[_c('li',[_vm._v("'고객 보상 제외 SHOP 설정'을 통해 SHOP에 등록된 전체 상품을 고객 보상 제외로 설정할 수 있습니다.")]),_c('li',[_vm._v("고객 보상 제외 SHOP 설정이 다른 설정들 보다 우선됩니다.")]),_c('li',[_vm._v("고객 보상 제외 조건을 설정하고 저장하는 즉시 고객 보상 제외 또는 대상 상품으로 분류됩니다.")]),_c('li',[_vm._v("고객 보상 제외 조건을 수정한 이후 생성된 주문완료(결제여부 무관) 건부터 고객 보상에서 제외됩니다.")])])]),_c('shop-preset',{model:{value:(_vm.selectedShops),callback:function ($$v) {_vm.selectedShops=$$v},expression:"selectedShops"}}),(_vm.settings)?_c('b-form',[_c('b-row',{staticClass:"my-3"},[_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){_vm.addExcludedShop(_vm.selectedShops.map(function (i) { return i.shop_id; }))}}},[_vm._v("추가")])],1),_c('b-col',{attrs:{"cols":"auto p-0"}},[_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){_vm.removeExcludedShop(_vm.excludedShopsData.filter(function (i) { return i.checked; }).map(function (i) { return i.shop_id; }))}}},[_vm._v("삭제")])],1)],1),_c('div',{staticClass:"my-3"},[_vm._v(" "+_vm._s(_vm.excludedShopsData.length)+"개 SHOP ")]),_c('b-row',[_c('b-col',[_c('hot-table',{ref:"excludedShopsTable",attrs:{"settings":{
                  width: '100%',
                  height: 'auto',
                  stretchH: 'last', 
                  colHeaders: ['', 'SHOP'],
                  columns: [
                    { data: 'checked', type: 'checkbox' },
                    {
                      renderer: function renderer(instance, td, row, col, prop, value, cellProperties) {
                        var shop_id = instance.getDataAtRowProp(row, 'shop_id');
                        var boutique = _vm.shopMap[shop_id].boutique;
                        td.innerText = shop_id + ". " + boutique;
                        return td;
                      },
                    } ],
                },"data":_vm.excludedShopsData,"licenseKey":"non-commercial-and-evaluation"}}),(!_vm.excludedShopsData.length)?_c('div',[_vm._v(" 추가된 SHOP이 없습니다. ")]):_vm._e()],1)],1)],1):_vm._e()],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-card',[_c('b-row',{attrs:{"align-h":"end"}},[_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v("저장")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }